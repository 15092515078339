import React, { useState, useEffect } from "react";
import "../assets/css/Gallery.css";

// Lista de imágenes estáticas
const imageList = [
  "IMG_8329.JPG",
  "IMG_8338.JPG",
  "IMG_8343.JPG",
  "IMG_8344.JPG",
  "IMG_8345.JPG",
  "IMG_8346.JPG",
  "IMG_8347.JPG",
  "IMG_8348.JPG",
  "IMG_8358.JPG",
  "IMG_8359.JPG",
  "IMG_8390.PNG",
  "IMG_8927.JPG",
  "Premios festival Freirina Live rock.JPG",
];

const MyBeginnings = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadedImages, setLoadedImages] = useState({});
  const [videoSrc, setVideoSrc] = useState(null);
  const [posterSrc, setPosterSrc] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Abrir visor con la imagen seleccionada
  };

  const closeImageViewer = () => {
    setSelectedImage(null); // Cerrar visor
  };

  useEffect(() => {
    const loadMedia = async () => {
      const promises = imageList.map(async (image) => {
        const loadedImage = await import(`../assets/img/mei-inizi/${image}`);
        return { [image]: loadedImage.default };
      });

      const resolvedImages = await Promise.all(promises);
      setLoadedImages(Object.assign({}, ...resolvedImages));

      // Cargar video y poster de forma asíncrona
      const video = await import("../assets/img/mei-inizi/video-output-198BDABE-1D5F-40DC-A2B8-87C3E0EE299F.mp4");
      const poster = await import("../assets/img/mei-inizi/mybeginnings.jpg");
      setVideoSrc(video.default);
      setPosterSrc(poster.default);
    };

    loadMedia();
  }, [])

  return (
    <div className="gallery-container">
      <h2>My Beginnings</h2>

      {/* Contenedor del video */}
      <div className="video-placeholder">
        {videoSrc && posterSrc ? (
          <video width="100%" controls poster={posterSrc}>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      {/* Grilla de imágenes */}
      <div className="image-grid">
        {imageList.map((image) => (
          <div
            key={image}
            className="image-thumbnail-portrait"
            onClick={() => handleImageClick(image)}
          >
            {loadedImages[image] ? (
              <img
                src={loadedImages[image]}
                alt={image}
                className="thumbnail-img-portrait"
                loading="lazy"
              />
            ) : (
              <p>Cargando...</p>
            )}
          </div>
        ))}
      </div>

      {/* Visor de imagen en pantalla completa */}
      {selectedImage && (
        <div className="image-viewer" onClick={closeImageViewer}>
          <span className="close-button">&times;</span>
          <img
            src={require(`../assets/img/mei-inizi/${selectedImage}`)}
            alt="Selected"
            className="full-screen-image"
          />
        </div>
      )}
    </div>
  );
};

export default MyBeginnings;
