import React, { useState, useEffect } from "react";
import "../assets/css/OnStage.css";

const OnStage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadedImages, setLoadedImages] = useState({});

  // Cargar el script de Instagram para incrustar videos
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.instagram.com/embed.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const loadImage = async (imagePath) => {
    const image = await import(`../assets/img/on-stage/${imagePath}`);
    return image.default;
  };

  useEffect(() => {
    const loadImages = async () => {
      const promises = imageList.map(async (image) => {
        const loadedImage = await import(`../assets/img/on-stage/${image.src}`);
        return { [image.src]: loadedImage.default };
      });

      const resolvedImages = await Promise.all(promises);
      setLoadedImages(Object.assign({}, ...resolvedImages));
    };

    loadImages();
  }, []);


  const imageList = [
    { src: "IMG_3251.JPG", credit: "" },
    { src: "IMG_5041.JPG", credit: "" },
    { src: "IMG_5042.JPG", credit: "" },
    { src: "IMG_8354.JPG", credit: "" },
    { src: "IMG_8920.JPG", credit: "" },
    { src: "IMG_8921.JPG", credit: "" },
    { src: "IMG_8923.JPG", credit: "" },
    { src: "IMG_8925.JPG", credit: "" },
    { src: "IMG_8926.JPG", credit: "" },
    { src: "IMG_8929.JPG", credit: "" },
    { src: "IMG_8930.JPG", credit: "" },
    { src: "IMG_8931.JPG", credit: "" },
    { src: "IMG_8934.JPG", credit: "" },
    { src: "IMG_9251.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9252.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9253.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9270.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9271.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9272.JPG", credit: "© Andrea Gelain Photography" },
    { src: "IMG_9397.JPG", credit: "" },
    { src: "4C684DF9-E6BE-4E94-AFB9-04E6E24F3505.jpg", credit: "" },
    { src: "33FEE278-85ED-4C00-AF93-DA07B5436D7C.jpg", credit: "" },
    { src: "77c33f86-5ba8-47c3-829c-62e919ecc22d.jpg", credit: "" },
    { src: "DSC_1495.JPG", credit: "" },
    { src: "Foto en concierto segunda.jpg", credit: "© AstiLirica" },
    { src: "Foto en concierto.JPG", credit: "© AstiLirica" },
    { src: "Foto profesional cantando.JPG", credit: "" },
    { src: "Foto profesional segunda.jpg", credit: "" },
    { src: "Foto profesional.JPG", credit: "" },
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  const youtubeLinks = [
    {
      title:
        "Gustavo Argandoña - “Fammi combattere” Orlando - Händel. (Fabio Biondi)",
      url: "https://youtu.be/mMTOdpmiB6U?si=IkaoxobOhtugDw_D",
    },
    {
      title:
        'J.S. Bach BWV 12 "Wir müssen durch viel trübsal, kreuz und krone" Gustavo Argandoña',
      url: "https://youtu.be/-EcL2lvjx0c?si=2cLXRxzZiofbUwfI",
    },
    {
      title:
        "Monteverdi - “Oblivion soave” - L’incoronazione di Poppea | Gustavo Argandoña",
      url: "https://youtu.be/QUI45mSps1Q?si=Et3cHwdw2L7EvAHx",
    },
    {
      title:
        "Gustavo Argandoña - “Pompe vane di morte… Dove sei, amato bene?” - Händel: Rodelinda",
      url: "https://youtu.be/z-0F5gpZoA4?si=chCRmD7jRau96rOn",
    },
    {
      title:
        "Gustavo Argandoña - Schäme dich, o Seele, nicht - Cantata BWV 147 J.S. Bach",
      url: "https://youtu.be/g6P7kNkrnKs?feature=shared",
    }
  ];

  return (
    <div className="onstage-container">
      <h2 className="py-2">On Stage</h2>
      <div style={{ padding: "0px 20px" }}>
        <video
          width="100%"
          controls
          poster={require("../assets/img/on-stage/presentacion.jpg")}
        >
          <source
            src={require("../assets/img/portraits/presentacion.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="media-container">
        {/* Video de Instagram */}
        <div className="instagram-video">
          <blockquote
            className="instagram-media"
            data-instgrm-captioned
            data-instgrm-permalink="https://www.instagram.com/reel/DBtQGIMgivo/?utm_source=ig_embed&amp;utm_campaign=loading"
            data-instgrm-version="14"
            style={{
              background: "#FFF",
              border: "0",
              borderRadius: "6px",
              boxShadow:
                "0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)",
              margin: "1px",

              padding: "0",


            }}
          >
            <div style={{ padding: "16px" }}>
              <a
                href="https://www.instagram.com/reel/DBtQGIMgivo/?utm_source=ig_embed&amp;utm_campaign=loading"
                style={{
                  background: "#FFFFFF",
                  lineHeight: "0",
                  padding: "0",
                  textAlign: "center",
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F4F4",
                      borderRadius: "50%",
                      height: "40px",
                      marginRight: "14px",
                      width: "40px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: "1",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F4F4F4",
                        borderRadius: "4px",
                        height: "14px",
                        marginBottom: "6px",
                        width: "100px",
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#F4F4F4",
                        borderRadius: "4px",
                        height: "14px",
                        width: "60px",
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{ padding: "19% 0" }}></div>
                <div
                  style={{
                    display: "block",
                    height: "50px",
                    margin: "0 auto 12px",
                    width: "50px",
                  }}
                >
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 60 60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(-511.000000, -20.000000)"
                        fill="#000000"
                      >
                        <path d="M556.869,30.41 C554.814,30.41 553.148,32.076 553.148,34.131 C553.148,36.186 554.814,37.852 556.869,37.852 C558.924,37.852 560.59,36.186 560.59,34.131 C560.59,32.076 558.924,30.41 556.869,30.41 M541,60.657 C535.114,60.657 530.342,55.887 530.342,50 C530.342,44.114 535.114,39.342 541,39.342 C546.887,39.342 551.658,44.114 551.658,50 C551.658,55.887 546.887,60.657 541,60.657 M541,33.886 C532.1,33.886 524.886,41.1 524.886,50 C524.886,58.899 532.1,66.113 541,66.113 C549.9,66.113 557.115,58.899 557.115,50 C557.115,41.1 549.9,33.886 541,33.886 M565.378,62.101 C565.244,65.022 564.756,66.606 564.346,67.663 C563.803,69.06 563.154,70.057 562.106,71.106 C561.058,72.155 560.06,72.803 558.662,73.347 C557.607,73.757 556.021,74.244 553.102,74.378 C549.944,74.521 548.997,74.552 541,74.552 C533.003,74.552 532.056,74.521 528.898,74.378 C525.979,74.244 524.393,73.757 523.338,73.347 C521.94,72.803 520.942,72.155 519.894,71.106 C518.846,70.057 518.197,69.06 517.654,67.663 C517.244,66.606 516.755,65.022 516.623,62.101 C516.479,58.943 516.448,57.996 516.448,50 C516.448,42.003 516.479,41.056 516.623,37.899 C516.755,34.978 517.244,33.391 517.654,32.338 C518.197,30.938 518.846,29.942 519.894,28.894 C520.942,27.846 521.94,27.196 523.338,26.654 C524.393,26.244 525.979,25.756 528.898,25.623 C532.057,25.479 533.004,25.448 541,25.448 C548.997,25.448 549.943,25.479 553.102,25.623 C556.021,25.756 557.607,26.244 558.662,26.654 C560.06,27.196 561.058,27.846 562.106,28.894 C563.154,29.942 563.803,30.938 564.346,32.338 C564.756,33.391 565.244,34.978 565.378,37.899 C565.522,41.056 565.552,42.003 565.552,50 C565.552,57.996 565.522,58.943 565.378,62.101"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div style={{ paddingTop: "8px" }}>
                  <div
                    style={{
                      color: "#3897f0",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                      fontWeight: "550",
                      lineHeight: "18px",
                    }}
                  >
                    View this post on Instagram
                  </div>
                </div>
              </a>
              <p
                style={{
                  color: "#c9c8cd",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  lineHeight: "17px",
                  marginBottom: "0",
                  marginTop: "8px",
                  overflow: "hidden",
                  padding: "8px 0 7px",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <a
                  href="https://www.instagram.com/reel/DBtQGIMgivo/?utm_source=ig_embed&amp;utm_campaign=loading"
                  style={{
                    color: "#c9c8cd",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "14px",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  A post shared by Impresa Lirica Tamagno (@lirica_tamagno)
                </a>
              </p>
            </div>
          </blockquote>
        </div>

        {/* Lista de enlaces de YouTube */}
        <div className="youtube-links">
          <h3>
            <i className="fab fa-youtube"></i> YouTube videos
          </h3>
          <ul>
            {youtubeLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Galería de imágenes */}
      <div className="gallery-container">
        <h3 className="my-3">Gallery</h3>
        <div className="image-grid">
          {imageList.map((image) => (
            <div key={image.src} className="image-thumbnail-portrait">
              {loadedImages[image.src] ? (
                <img
                  src={loadedImages[image.src]}
                  alt={image.src}
                  className="thumbnail-img-portrait"
                  loading="lazy"
                  onClick={() => handleImageClick(image)}
                />
              ) : (
                <p>Cargando...</p>
              )}
              {image.credit !== '' && <div className="watermark">{image.credit}</div>}
            </div>
          ))}

        </div>

        {selectedImage && (
          <div className="image-viewer">
            <span className="close-button" onClick={closeImageViewer}>
              &times;
            </span>
            <img
              src={require(`../assets/img/on-stage/${selectedImage.src}`)}
              alt="Selected"
              className="full-screen-image"
            />
            {selectedImage.credit && (
              <div className="watermark-fullscreen">{selectedImage.credit}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnStage;
