import React from 'react';
import '../assets/css/Banner.css';
import portraitDesktop from '../assets/img/portrait1.png';
import portraitSmall from '../assets/img/portrait3mobile.png';
import portraitMiddle from '../assets/img/portrait4.png';

const Banner = () => {
  return (
    <div className="banner">
      <img src={portraitSmall} alt="Banner Small" className="banner-image small" loading="lazy" />
      <img src={portraitMiddle} alt="Banner Middle" className="banner-image middle" loading="lazy" />
      <img src={portraitDesktop} alt="Banner Desktop" className="banner-image desktop" loading="lazy" />

      {/* Label solo en la versión pequeña */}
      <div className="banner-content small">
        <h1>Gustavo</h1>
        <h1 className='lastname'>Argandoña</h1>
        <p>Countertenor</p>
      </div>
    </div>
  );
};

export default Banner;
